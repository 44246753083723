import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
// import logo from "./logo.svg";
import Test from "./Components/Test";
import "./common.scss";

// ==> Client Config 372 <== //

import clientConfig from "./Config/Colville-nse/clientConfig.js";

// ==================== //

import SampleForm from "./Components/FormSample";
import Dictionary from "./Components/Dictionary";
import NavigationMain from "./Components/Navigation-main";
import Welcome from "./Components/Welcome/index";
import Lessons from "./Components/Lessons/index";
import MediaLibrary from "./Components/MediaLibrary";
import Quiz from "./Components/Dictionary/Quiz";
import QRScanner from "./Components/QRScanner/index";
import LoginScreen from "./Components/Admin/index";
import GameData from "./Components/GameData/index";
import { motion } from "framer-motion";

function App() {
  // TODO: dims should update if window resizes
  const [dims, setDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [quizData, setQuizData] = useState(null);
  const [activeTab, setActiveTab] = useState("welcome");
  const [keyboardHeight, setKeyboardHeight] = useState(40);
  const [dictionaryScreen, setDictionaryScreen] = useState('search');
  useEffect(() => {
    const handleResize = () => {
      setDims({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    const findTab = () => {
      let url = window.location.pathname;
      url = url.slice(1);
      setActiveTab(`${url}`);
    };
    window.addEventListener("load", findTab);
  }, []);

  const getAndStoreQuizData = (chosenData) => {
    setQuizData(chosenData);
    localStorage.clear();
    localStorage.setItem("QuizData", JSON.stringify(chosenData));
  };

  return (
    <Router>
      <div className="App">
        <div className="main-layout-grid">
          {/* HEADER start */}
          <div className="header">
            <motion.div
              className="Main-headerTxt"
              key="headerTxt"
              initial="start"
              animate="end"
              exit="start"
              variants={{
                start: {
                  scale: 1,
                  opacity: 1,
                  rotateX: 0,
                  backgroundColor: "rgba(20,20,300,1)",
                },
                end: {
                  scale: 1,
                  opacity: 0.3,
                  rotateX: 360,
                  backgroundColor: "rgba(20,20,300,0)",
                  transition: {
                    delay: 1.5,
                    duration: 1.5,
                  },
                },
              }}
            >
              <motion.div
                key="headerTxt"
                initial="start"
                animate="end"
                exit="start"
                variants={{
                  start: {
                    scale: 1,
                    opacity: 1,
                    rotateX: 0,
                  },
                  end: {
                    scale: 1,
                    opacity: 0,
                    rotateX: 720,
                    transition: {
                      delay: 5.5,
                      duration: 2,
                    },
                  },
                }}
              >
                <div>Created by Native Teaching Aids LLC© </div>
              </motion.div>
            </motion.div>
            <div className="header-left-image" />
            <Link to="/welcome">
              <div
                onClick={() => setActiveTab("welcome")}
                className="header-center-image"
              />
            </Link>
            <div className="header-right-image" />
          </div>
          <div className="spacer1" />
          <div className="spacer2" />
          <div className="spacer3" />
          <div className="spacer4" />
          {/* HEADER end */}
          <div className="main-content">
            <Switch>
              <Route path="/Dictionary/Quiz">
                <Quiz quizData={quizData} />
              </Route>
              <Route path="/ML">
                <MediaLibrary />
              </Route>
              <Route path="/medialibrary">
                <MediaLibrary />
              </Route>
              <Route path="/welcome">
                <Welcome dims={dims} clientConfig={clientConfig} />
              </Route>
              <Route path="/login">
                <LoginScreen />
              </Route>
              <Route path="/lessons">
                <Lessons />
              </Route>
              <Route path="/test">
                <Test></Test>
              </Route>
              <Route path="/edit/gameData">
                <GameData></GameData>
              </Route>
              <Route path="/Dictionary">
                <Dictionary
                  keyboardHeight={keyboardHeight}
                  setKeyboardHeight={setKeyboardHeight}
                  setQuizData={getAndStoreQuizData}
                  dims={dims}
                  searchOrBrowse={dictionaryScreen}
                  setSearchOrBrowse={setDictionaryScreen}
                />
              </Route>
              <Route path="/Form">
                <SampleForm />
              </Route>
              <Route path="/qrScanner">
                <QRScanner />
              </Route>
              <Route path="/">
                <Welcome dims={dims} clientConfig={clientConfig} />
              </Route>
            </Switch>
          </div>
          <div className="main-nav">
            <NavigationMain
              clientConfig={clientConfig}
              dims={dims}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          {/* FOOTER start */}

          <div className="footer">
            {!window.location.href.includes("qrScanner") ? (
              <Popup
                content='scan QR codes here!'
                trigger={
                  <Link
                    onClick={() => setActiveTab("qrScanner")}
                    to={{ pathname: "/qrScanner" }}
                    style={{
                      position: "absolute",
                      bottom: 55,
                      right: 15,
                      zIndex: 99,
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      backgroundColor: "rgba(0,130,214,1)",
                      boxShadow: "1px 3px 4px black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      name="qrcode"
                      style={{ color: "white", fontSize: 30, height: 16 }}
                      fitted
                    />
                  </Link>
                }
              />
            ) : null}
            <div style={{ color: "rgba(200,200,200,0.8)" }}>© 2023 Native Teaching Aids LLC.</div>
          </div>
          {/* FOOTER end */}
        </div>
      </div>
    </Router>
  );
}

export default App;
