
// ==> client config <== //

// const langDir = "BF";
const langDir = "Colville-nse";
// THIS is the language name that is displayed to the user
const langName = "Nsəlxcin";
// ===================== //

const baseConfigDir = "./Config/";

export const fullConfigDir = baseConfigDir + langDir;

const keyboard = [
  "a", "c", "c̓", "ə", "h", "i", "k", "k̓", "kʷ", "k̓ʷ", "l", "l̓", "ƛ̓", "m", "m̓", "n", "n̓", "p", "p̓",
  "q", "q̓", "qʷ", "q̓ʷ", "r", "r̓", "s", "t", "t̓", "u", "w", "w̓", "x", "xʷ", "x̌",
  "x̌ʷ", "y", "y̓", "ʕ", "ʕʷ", "ʕ̓", "ʕ̓ʷ", "ʔ", "ɣ",
]

const lookupTable = {
  a: ["a"],
  // b: ["b"],
  c: ["c"],
  // d: ["d"],
  e: ["ə"],
  // f: ["f"],
  // g: ["g"],
  h: ["h"],
  i: ["i"],
  // j: ["j"],
  k: ["k", "k̓", "kʷ", "k̓ʷ"],
  l: ["l", "l̓", "ƛ̓"],
  m: ["m", "m̓"],
  n: ["n", "n̓"],
  // o: ["o", "ó", "óó"],
  p: ["p", "p̓"],
  q: ["q", "q̓", "qʷ", "q̓ʷ"],
  r: ["r", "r̓"],
  s: ["s"],
  t: ["t", "t̓"],
  u: ["u"],
  // v: ["v"],
  w: ["w", "w̓",],
  x: ["x", "xʷ", "x̌", "x̌ʷ"],
  y: ["y", "y̓", "ʕ", "ʕʷ", "ʕ̓", "ʕ̓ʷ", "ʔ", "ɣ"],
  z: ["z"],
};

const searchTypesArr = [
  { key: "Noun", text: "Nouns", value: "Noun" },
  { key: "Verb", text: "Verbs", value: "Verb" },
  { key: "Affixes", text: "Affixes", value: "Endings" },
  { key: "Prefixes", text: "Prefixes", value: "Beginnings" },
  { key: "Phrases", text: "Phrases", value: "MultiwordPhrases" },
];

const langArr = [
  { key: "Language", text: langName, value: "Language" },
  { key: "English", text: "English", value: "English" },
];

const dataTypeToCompontent = {
  Words: "Words",
};

export const collectionsToDisplay = (collectionName) => {
  const collectionO = {
    I_Nouns: () => "Noun",
    Words: () => "Words",
    I_Verbs: () => "Verb",
  };

  if (typeof collectionO[collectionName] !== "function") {
    return collectionName; // display actual name if not in list.
  }
  return collectionO[collectionName]();
};

export const Show_Quiz = true;

function mediaDirSwitch(lang) {
  const langServerName = {
    "BF": "blackfootmedia",
    "Salish": "salishmedia",
    "Colville-nse": "colville-nse-media",
    "Colville-tit": "colville-tit-media",
  }
  const staticgoogleapis = "https://storage.googleapis.com/";
  return staticgoogleapis + langServerName[lang];
}

// const IMAGE_PRE = "https://storage.googleapis.com/blackfootmedia/Image/";
const IMAGE_PRE = mediaDirSwitch(langDir) + "/Image/";
const AUDIO_PRE = mediaDirSwitch(langDir) + "/Audio/";
// const AUDIO_PRE = "https://storage.googleapis.com/blackfootmedia/Audio/";

const config = () => {
  const clientConfigs = {
    Salish: {
      baseDir: "https://storage.googleapis.com/salishmedia",
      NativeLanguage: "Salish",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    },
    BF: {
      baseDir: "https://storage.googleapis.com/blackfootmedia",
      NativeLanguage: "Blackfoot",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    },
    colvilleTit: {
      baseDir: "https://storage.googleapis.com/colville-tit-media",
      NativeLanguage: "Titoqatímt",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
      showAdvancedSearchButton: false,
      dictionaryTitle: "Dictionary",
    },
    Nsəlxcin: {
      baseDir: "https://storage.googleapis.com/colville-nse-media",
      NativeLanguage: "Nsəlxcin",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
      dictionaryTitle: "Dictionary",
      showAdvancedSearchButton: false,
      lowerCaseNative: true,
    }
  };
  return clientConfigs[langName];
};

export default config;
