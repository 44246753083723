/*
  COPY THESE INSTRUCTIONS TO ALL NEW LANGUAGE CONFIG FILES (FOR EASY ACCESS)

Three files need their import statements altered for a Language Switch
1. App.scss - handles all styling changes
2. App.js - Enabling contents to change throughout the app to fit the Language
3. Store/firestore.js - Enables data from correct firestore database
  */

const tabNameWelcome = "Welcome";
const groupName = "Okanagan";
const tabNameDictionary = "Dictionary";
const firebaseConfig = {
  databaseURL: "https://blackfoot-426cd.firebaseio.com",
  apiKey: "AIzaSyD-IUvMcEO80wX8oXzQMWOWmoweOvevdBM",
  authDomain: "colville-nse.firebaseapp.com",
  projectId: "colville-nse",
  storageBucket: "colville-nse.appspot.com",
  messagingSenderId: "685286461557",
  appId: "1:685286461557:web:f616880c6b188bf2d5e83c",
  measurementId: "G-2BT0SF6XEP"
};

const linkArray = [
];

const welcomeEngArray = [
];

const welcomeLangArray = [
];

const welcomeLogoArray = [
  "logo-nta",
  "logo-pikani",
  "logo-bfcc",
  "logo-montana-seal",
];

const welcomeMessageArray = [
  "Developed by Native Teaching Aids, in conjunction with the Confederated Tribes of the Colville",
];

const config = {
  linkArray: linkArray,
  engArray: welcomeEngArray,
  langArray: welcomeLangArray,
  logoArray: welcomeLogoArray,
  messageArray: welcomeMessageArray,
  tabNameWelcome: tabNameWelcome,
  tabNameDictionary: tabNameDictionary,
  firebaseConfig: firebaseConfig,
};

export default config;
export { linkArray, welcomeEngArray, welcomeLangArray, firebaseConfig };
