import "./index.scss";
import React from "react";

const Tabs = (props) => {
  let activeTabContents = props.panes.find(
    (datum) => datum.key === props.activeTab
  );
  return (
    <div className={props.showLeftBar ? "tab-outer-container" : "tab-outer-container-extended"}>
      <div className="tab-middle-container">
        {props.panes.map((datum) => {
          return (
            <div key={datum.key} className="tab-container">
              <div
                className={
                  props.activeTab !== datum.key
                    ? "tab noselect"
                    : "tabActive noselect"
                }
                key={datum.key}
                onClick={() => {
                  props.setActiveTab(datum.key);
                  if (props.setActiveTabData) props.setActiveTabData(datum);
                }}
              >
                {datum.menuItem}
              </div>
            </div>
          );
        })}
      </div>
      {activeTabContents !== undefined ? (
        <div className="content">{activeTabContents.content}</div>
      ) : null}
    </div>
  );
};

export default Tabs;
