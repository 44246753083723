import React from "react";
// import { Icon, Modal, Loader } from "semantic-ui-react";
import ImagesDisplay from "./Images";
import AudioDisplay from "./Audio";
import AudioDisplaylg from "./AudioLg";
// import config from "../../config.js";

const MediaDisplay = (props) => {
  let displayDOM = <div></div>;
  switch (props.obj.data.type) {
    case "audio":
      if (props.large) {
        displayDOM = <AudioDisplaylg {...props} />;
      } else {
        displayDOM = <AudioDisplay {...props} />;
      }
      break;
    case "image":
    default:
      displayDOM = <ImagesDisplay {...props} />;
      break;
  }
  return displayDOM;
};

export default MediaDisplay;
