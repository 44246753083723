/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import "semantic-ui-css/semantic.min.css";
import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
  useLayoutEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Icon, Button, Popup } from "semantic-ui-react";
import Browse from "./Browse";
import {
  // debounce,
  setQuizData,
  fetchImage,
  // fetchAudio,
} from "../../Components/nonUIFuncs";
import Tabs from "../Tabs";
// import VideoDisplay from "../VideoDisplay";
import {
  selectFirestore,
  getPagedQuizData,
  getFullDataFirebaseCollection,
  getSearchStartsWithMultiCollection,
  getSearchStartsWithFilter,
  getRoots,
  getOtherWordsWithRoot,
  getURLWord,
} from "../../Store/firestore";
// import { defaultsDeep } from "lodash-es";
import { Link, useLocation, useHistory } from "react-router-dom";
import config, { collectionsToDisplay, Show_Quiz } from "../../configData";
import DisplayCollectionAlt from "../DisplayCollectionAlt";
import { motion, AnimatePresence } from "framer-motion";
import { specialCharsA } from "../SpecialCharsEntry";
import "./index.scss";
import "../../App.scss";
import AdvancedSearchBar from "../AdvancedSearchBar";
import DictionaryContentRight from "./DictionaryContentRight";

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const { baseDir, searchTypesArr, langArr, dictionaryTitle, showAdvancedSearchButton } = config();
console.log("==DICT: ", dictionaryTitle);
let searchBoxWidth = 1000;
// let twoLines = false;
// let initialState = { panes: [] };

const Dictionary = (props) => {
  const params = new URLSearchParams();
  // defualt settings
  const defaultSearchCollections = ["Words"]; //TODO: change search collections for new data modal
  const defaultLang = "English";
  // Hooks
  const dispatch2 = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const fireStoreData = useSelector(selectFirestore);

  const [selectedBrowseChar, setSelectedBrowseChar] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [browseTab, setBrowseTab] = useState("");
  const [activeTabData, setActiveTabData] = useState({});
  const [browseTabData, setBrowseTabData] = useState({});
  const [searchType, setSearchType] = useState(defaultSearchCollections);
  const [searchGrammaticalCategory, setsearchGrammaticalCategory] = useState([]);
  const [langType, setLanguageType] = useState(defaultLang);
  let collectionName = query.get("collection");
  let id = query.get("id");
  let letter = query.get('letter');

  const reducer = (currentState, action, event) => {
    switch (action.type) {
      case "add":
        params.append('screen', props.searchOrBrowse);
        params.append('collection', action.tab.data.data.collectionName);
        params.append('id', action.tab.key);
        let letter = query.get("letter");
        if (letter !== null) {
          params.append('letter', letter);
        }
        history.push({ search: params.toString() });
        let panesKeys = [];
        currentState.panes.map((datum) => {
          panesKeys.push(datum.key);
        });
        let browseKeys = [];
        currentState.browsePanes.map((datum) => {
          browseKeys.push(datum.key);
        });
        if (action.browse === false && panesKeys.includes(action.tab.key)) {
          setActiveTab(action.tab.key);
          setActiveTabData(action.tab.data.data);
          return { ...currentState, panes: [...currentState.panes] };
        } else if (
          action.browse === true &&
          browseKeys.includes(action.tab.key)
        ) {
          setBrowseTab(action.tab.key);
          setBrowseTabData(action.tab.data.data);
          return { ...currentState, browsePanes: [...currentState.browsePanes] };
        } else {
          let tabData = action.tab.data.data;
          let prepedQuizData = setQuizData(tabData);
          dispatch2(getPagedQuizData(prepedQuizData));
          dispatch2(
            getRoots({ rootWordEnglish: tabData.English, root: tabData.Root })
          );
          dispatch2(
            getOtherWordsWithRoot({
              rootEnglish: tabData.English,
              root: tabData.Root,
            })
          );
          if (action.browse) {
            setBrowseTab(action.tab.key);
            setBrowseTabData(tabData);
          } else {
            setActiveTab(action.tab.key);
            setActiveTabData(tabData);
          }
          let bgImg = fetchImage(tabData.App_Art);
          bgImg = "`" + bgImg + "`";
          let obj = {
            ...action.tab,
            menuItem: (
              <div
                onClick={(e) => {
                  e.stopPropagation(true);
                  dispatch({
                    type: "add",
                    tab: action.tab,
                    browse: action.browse ? true : false,
                  });
                }}
                key={action.tab.key}
              >
                {tabData.Language}
                <DisplayCollectionAlt
                  collectionName={tabData.collectionName}
                  grammaticalCategory={tabData.Grammatical_Category}
                />{" "}
                <Icon
                  fitted
                  name="close"
                  onClick={(e) => {
                    e.stopPropagation(true);
                    dispatch({
                      type: "remove",
                      tab: action.tab.key,
                      browse: action.browse ? true : false,
                    });
                  }}
                />
              </div>
            ),
            content: (
              <div className="dictionary-tab-container">
                <div className="dictionary-tab-header">
                  <div className="dictionary-tab-header-lang">
                    <div>
                      {
                        tabData["ML2 Speaker Audio_File"] &&
                        <Button circular onClick={(e) => {
                          console.log("TABDATA: ", tabData);
                          const audioEl = new Audio(`${baseDir}/Audio/${tabData["ML2 Speaker Audio_File"]}`);
                          audioEl.play();
                        }}>
                          <Icon.Group size="big">
                            <Icon fitted name="play circle outline" />
                          </Icon.Group>
                        </Button>
                      }
                      &nbsp;
                      {tabData.Language}
                      {tabData["Pronunciation Key"].length > 0
                        ? `(${tabData["Pronunciation Key"]})`
                        : null}
                    </div>
                    {Show_Quiz && (prepedQuizData.Grammatical_Category !== undefined ||
                      prepedQuizData.Part_Of_Speach !== undefined) ? (
                      <Link
                        to={{ pathname: "/Dictionary/Quiz" }}
                        onClick={() => props.setQuizData(tabData)}
                        className="dictionary-quiz-button noselect"
                      >
                        Take A Quiz
                      </Link>
                    ) : null}
                  </div>
                </div>
                <div className="dictionary-tab-content-container">
                  <DictionaryContentCenter tabData={tabData} />
                  <DictionaryContentRight
                    display={true}
                    tabData={tabData}
                    dims={props.dims}
                  />
                </div>
              </div>
            ),
          };
          if (action.browse) {
            return {
              ...currentState,
              browsePanes: [...currentState.browsePanes, obj],
            };
          } else {
            return {
              ...currentState,
              panes: [...currentState.panes, obj],
            };
          }
        }
      case "remove":
        params.append("screen", props.searchOrBrowse);
        if (action.browse === false) {
          if (currentState.panes.length > 1) {
            if (activeTab === action.tab) {
              if (action.tab !== currentState.panes[0].key) {
                setActiveTab(currentState.panes[0].key);
                setActiveTabData(currentState.panes[0].data.data);
                params.append(
                  "collection",
                  currentState.panes[0].data.data.collectionName
                );
                params.append('id', currentState.panes[0].key);
              } else {
                setActiveTab(currentState.panes[1].key);
                setActiveTabData(currentState.panes[1].data.data);
                params.append(
                  "collection",
                  currentState.panes[1].data.data.collectionName
                );
                params.append('id', currentState.panes[1].key);
              }
            } else {
              params.append("collection", collectionName);
              params.append("id", id);
            }
          } else {
            setActiveTab('');
            setActiveTabData({});
            params.delete("collection");
            params.delete("id");
          }
          history.push({ search: params.toString() });
          return {
            ...currentState,
            panes: currentState.panes.filter(
              (datum) => datum.key !== action.tab
            ),
          };
        } else {
          if (currentState.browsePanes.length > 1) {
            if (browseTab === action.tab) {
              if (action.tab !== currentState.browsePanes[0].key) {
                setBrowseTab(currentState.browsePanes[0].key);
                setBrowseTabData(currentState.browsePanes[0].data.data);
                params.append(
                  "collection",
                  currentState.browsePanes[0].data.data.collectionName
                );
                params.append("id", currentState.browsePanes[0].key);
              } else {
                setBrowseTab(currentState.browsePanes[1].key);
                setBrowseTabData(currentState.browsePanes[1].data.data);
                params.append(
                  "collection",
                  currentState.browsePanes[1].data.data.collectionName
                );
                params.append("id", currentState.browsePanes[1].key);
              }
            } else {
              params.append("collection", collectionName);
              params.append("id", id);
            }
          } else {
            setBrowseTab('');
            setBrowseTabData({});
            params.delete("collection");
            params.delete("id");
          }
          history.push({ search: params.toString() });
          return {
            ...currentState,
            browsePanes: currentState.browsePanes.filter(
              (datum) => datum.key !== action.tab
            ),
          };
        }
      default:
        throw new Error();
    }
  };

  const transitionContainer = document.querySelector(
    ".dictionary-keyboard-container"
  );
  const transition = document.querySelector(
    ".dictionary-keyboard-inner-container"
  );
  if (transitionContainer !== null) {
    window.onresize = () => {
      props.setKeyboardHeight(transition.scrollHeight);
    };
    transitionContainer.addEventListener("transitionend", () => {
      props.setKeyboardHeight(transition.scrollHeight);
    });
    transitionContainer.style.height = transition.scrollHeight;
  }
  const changeKeyboardDisplay = () => {
    if (showKeyboard) {
      props.setKeyboardHeight(transition.scrollHeight);
      setShowKeyboard(false);
    } else {
      setShowKeyboard(true);
    }
  };
  useLayoutEffect(() => {
    // the value in the following is the actual collections to be searched
    //TODO: these should be in the config file probably
    // Do this before the first render of the screen
    dispatch2(getFullDataFirebaseCollection("Dictionary")); //TODO: this is getting the entire collection this should be a search?
    const searchTypeDis = () => {
      if (searchBoxWidth > 231 || window.innerWidth > 930) {
        //TODO: this should prob be a DOM ref
        searchBoxWidth = document.getElementById(
          "dictionary-sl-dropdown-search-cont"
        );
        if (searchBoxWidth !== null) {
          searchBoxWidth = searchBoxWidth.getBoundingClientRect().width;
        } else {
          window.removeEventListener("resize", searchTypeDis);
          window.removeEventListener("load", searchTypeDis);
        }
      }
    };
    window.addEventListener("resize", searchTypeDis);
    window.addEventListener("load", searchTypeDis);
  }, [dispatch2]);

  const saveData = (chosenData) => {
    localStorage.setItem("QuizData", JSON.stringify(chosenData));
  };
  const url = window.location.href;
  const [state, dispatch] = useReducer(reducer, { panes: [], browsePanes: [] });
  const [showLeftBar, setShowLeftBar] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [partOfSpeech, setPartOfSpeech] = useState(null);
  let partOfSpeechArr = ["Verb", "Noun", "Phrase"];
  const [personView, setPersonView] = useState(null);
  let personViewArr = ["1st", "2nd", "3rd", "4th"];
  const [pluralization, setPluralization] = useState(null);
  let pluralizationArr = ["Singular", "Plural"];
  const [animacy, setAnimacy] = useState(null);
  let animacyArr = ["Animate", "Inanimate"];
  const [tense, setTense] = useState(null);
  let tenseArr = ["Past", "Present", "Future"];
  const [transitivity, setTransitivity] = useState(null);
  let transitivityArr = ["Transitive", "Intransitive"];
  const [progressiveness, setProgressiveness] = useState(null);
  let progressivenessArr = ["Irrealis", "Progressive", "Perfect"];
  const [value, setValue] = useState("");

  const fetchData = async () => {
    if (id && collectionName) {
      await dispatch2(getURLWord(collectionName, id));
    }
    if (
      fireStoreData.urlDataResults &&
      fireStoreData.urlDataResults.data !== undefined
    ) {
      dispatch({
        browse: props.searchOrBrowse === 'browse',
        type: "add",
        tab: {
          key: fireStoreData.urlDataResults.id,
          value: fireStoreData.urlDataResults.id,
          text: fireStoreData.urlDataResults.data.English,
          data: { ...fireStoreData.urlDataResults },
        },
      });
    }
  };

  const getPage = () => {
    let screen = query.get('screen');
    let urlletter = query.get('letter');
    if (screen !== null) {
      props.setSearchOrBrowse(screen);
      params.append("screen", screen);
    } else {
      props.setSearchOrBrowse('search');
      params.append("screen", 'search');
    }
    if (urlletter !== null) {
      params.append('letter', urlletter);
    } else {
      params.delete('letter');
    }
    if (collectionName !== null) {
      params.append('collection', collectionName);
    } else {
      params.delete('collection');
    }
    if (id !== null) {
      params.append('id', id);
    } else {
      params.delete('id');
    }
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    getPage();
    window.addEventListener("beforeunload", getPage);
    fetchData();
    return (
      window.removeEventListener("beforeunload", getPage)
    );
    // [fetchData, getPage]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setAdvancedDisplay = () => {
    setShowAdvanced(showAdvanced ? false : true);
    setPartOfSpeech(null);
    setPersonView(null);
    setPluralization(null);
    setAnimacy(null);
    setTense(null);
    setTransitivity(null);
    setProgressiveness(null);
  };

  // console.log("Search Data Results: ", fireStoreData.searchDataResults);

  const handleDropdownChange = (e, data) => {
    dispatch({
      browse: false,
      type: "add",
      tab: data.options.find(
        // TODO: has to find correct collection too.
        (datum) => data.value === datum.key
      ),
    });
  };

  let dictionaryTxt = dictionaryTitle;
  if (props?.clientConfig?.tabNameDictionary) {
    dictionaryTxt = props.clientConfig.tabNameDictionary;
  } else {
    console.log("dictionaryTxt is undefined", props)
  }

  return (
    <AnimatePresence>
      <motion.div
        key="sinaiatsisMain"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          hidden: {
            scale: 1,
            opacity: 0,
            x: 500,
          },
          visible: {
            scale: 1,
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.4,
              duration: 0.5,
              type: "spring",
            },
          },
        }}
      >
        <div className="current-screen-style">
          <div className="dictionary-header-container">
            <div className="dictionary-header-title">{dictionaryTxt}</div>
            <div className="dictionary-header-buttons">
              <Button.Group>
                <Button
                  as={Link}
                  // to and onClick should be re-written to properly utilize query, params, and history.
                  to={`/dictionary?screen=search${activeTab.length > 0
                    ? `&collection=${activeTabData.collectionName}&id=${activeTab}`
                    : ''
                    }`}
                  icon
                  labelPosition="left"
                  color="blue"
                  onClick={() => props.setSearchOrBrowse("search")}
                >
                  <Icon size="large" name="search" />
                  Search
                </Button>
                <Button.Or />
                <Button
                  as={Link}
                  to={`/dictionary?screen=browse${browseTab.length > 0
                    ? `&collection=${browseTabData.collectionName}&id=${browseTab}`
                    : ''
                    }`}
                  icon
                  labelPosition="right"
                  color="blue"
                  onClick={() => {
                    props.setSearchOrBrowse("browse");
                    setShowKeyboard(false);
                  }}
                >
                  <Icon size="large" name="book" />
                  Browse
                </Button>
              </Button.Group>
              {showAdvancedSearchButton &&
                <Button
                  onClick={setAdvancedDisplay}
                  active={showAdvanced}
                  toggle
                  className="dictionary-header-button-advanced"
                  icon
                  labelPosition="left"
                  color="blue"
                >
                  <Icon size="large" name="settings" />
                  Advanced
                </Button>
              }
            </div>
          </div>
          <div className="dictionary-maincontent">
            {props.searchOrBrowse !== "browse" ? (
              <div className="dictionary-search-and-advanced-container">
                {/* Keyboard */}
                <div
                  className="dictionary-keyboard-container"
                  style={{
                    height: showKeyboard ? props.keyboardHeight : 0,
                    overflow: "hidden",
                  }}
                >
                  <div className="dictionary-keyboard-inner-container">
                    {specialCharsA.map((char) => {
                      return (
                        <Button
                          key={char}
                          className="dictionary-keyboard-key"
                          size="small"
                          onClick={() => setValue(value + char)}
                        >
                          {char}
                        </Button>
                      );
                    })}
                  </div>
                </div>
                <div className="dictionary-single-line-search">
                  <div className="dictionary-sl-left">
                    <div className="dictionary-sl-dropdown-search-lang">
                      <Button
                        id="dictionary-sl-dropdown-search-cont"
                        as="div"
                        labelPosition="right"
                      >
                        <Button
                          onClick={changeKeyboardDisplay}
                          compact
                          color="blue"
                          style={{
                            zIndex: 999,
                            borderRadius: showKeyboard
                              ? "0px 0px 0px 5px"
                              : "5px 0px 0px 5px",
                          }}
                        >
                          <Icon size="large" name="language" />
                        </Button>
                        <Dropdown
                          value={value}
                          className="dictionary-dropdownControlled-dd"
                          fluid
                          minCharacters={2}
                          noResultsMessage="No results found"
                          options={
                            fireStoreData &&
                            fireStoreData.searchDataResults &&
                            formatDataForDropDown(
                              fireStoreData.searchDataResults, langType
                            )
                          }
                          selectOnBlur={false}
                          searchQuery={value}
                          search
                          selection
                          placeholder="Search"
                          onChange={(e, data) => handleDropdownChange(e, data)}
                          onSearchChange={(e, data) => {
                            setValue(data.searchQuery);
                            dispatch2(
                              getSearchStartsWithFilter({
                                searchString: data.searchQuery,
                                grammaticalCategory: [...searchGrammaticalCategory],
                                searchNative: (langType !== "English"),
                                Advanced: showAdvanced,
                                pluralization,
                                animacy,
                                partOfSpeech,
                                personView,
                                tense,
                                transitivity,
                                progressiveness,
                              })
                            ); //TODO: maybe move this to a state store so that it can get cleared nicer?
                          }}
                        />
                      </Button>
                      <div>
                        <Popup
                          content="Langauge Selection"
                          trigger={
                            <Dropdown
                              // placeholder="Language"
                              defaultValue={defaultLang}
                              fluid
                              selection
                              options={langArr}
                              onChange={(e, d) => {
                                setLanguageType(d.value);
                                d.value === "English"
                                  ? setShowKeyboard(false)
                                  : setShowKeyboard(true);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        searchBoxWidth < 231
                          ? "dictionary-sl-dropdown-type-cont"
                          : ""
                      }
                    >
                      <Popup
                        content="Search Types"
                        position="top center"
                        trigger={
                          <Dropdown
                            // placeholder="Search Type"
                            //defaultValue={defaultSearchCollections}
                            fluid
                            multiple
                            minCharacters={10}
                            selection
                            options={searchTypesArr}
                            onChange={(e, data) => {
                              console.log("data.value: ", data.value);
                              setsearchGrammaticalCategory(data.value);
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {showAdvanced ? (
              <AdvancedSearchBar
                className="dictionary-advanced-search"
                buttonGroupsArray={[
                  props.searchOrBrowse === "browse"
                    ? {
                      array: partOfSpeechArr,
                      state: partOfSpeech,
                      setStateFunc: setPartOfSpeech,
                    }
                    : undefined,
                  {
                    array: pluralizationArr,
                    state: pluralization,
                    setStateFunc: setPluralization,
                  },
                  {
                    array: personViewArr,
                    state: personView,
                    setStateFunc: setPersonView,
                  },
                  {
                    array: animacyArr,
                    state: animacy,
                    setStateFunc: setAnimacy,
                  },
                  {
                    array: tenseArr,
                    state: tense,
                    setStateFunc: setTense,
                  },
                  {
                    array: transitivityArr,
                    state: transitivity,
                    setStateFunc: setTransitivity,
                  },
                  {
                    array: progressivenessArr,
                    state: progressiveness,
                    setStateFunc: setProgressiveness,
                  },
                ]}
              />
            ) : null}
            {props.searchOrBrowse === "browse" ? (
              <Browse
                dispatch={dispatch}
                partOfSpeach={partOfSpeech}
                personView={personView}
                pluralization={pluralization}
                animacy={animacy}
                tense={tense}
                transitivity={transitivity}
                progressiveness={progressiveness}
                panes={state.browsePanes}
                activeTab={browseTab}
                setActiveTab={setBrowseTab}
                selected={selectedBrowseChar}
                setSelected={setSelectedBrowseChar}
                letter={letter}
              />
            ) : state.panes.length > 0 ? (
              <div className="dictionary-tabs-container">
                <DictionaryLeftBar
                  tabData={activeTabData}
                  display={true}
                  dispatch={dispatch}
                  showLeftBar={showLeftBar}
                  setShowLeftBar={setShowLeftBar}
                  smallScreen={props.dims.width < 867}
                />
                <Tabs
                  panes={state.panes}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  showLeftBar={showLeftBar}
                />
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

// ==> seprate components, can be separated out later <== //

const DictionaryLeftBar = (props) => {
  const fireStoreData = useSelector(selectFirestore);
  if (!props.display) {
    return <></>;
  }
  let matchedRoots = [];
  let relatedRoots = [];
  if (fireStoreData.relatedRoots) {
    fireStoreData.relatedRoots.map((d) => {
      if (d?.data?.English === fireStoreData?.roots[0]?.data?.English) {
        relatedRoots.push(d.data.Language);
      }
    });
  }
  let relatedWords = [];
  if (fireStoreData.wordsWithRoot) {
    fireStoreData.wordsWithRoot.map((datum) => {
      if (datum.data.Root === props.tabData.Root) {
        matchedRoots.push(datum);
      }
      if (relatedRoots.includes(datum.data.Root)) {
        relatedWords.push(datum);
      }
    });
  }
  if (!fireStoreData.wordsWithRoot || matchedRoots.length === 0) {
    return (
      <div
        className={
          props.showLeftBar
            ? "dictionary-tab-content-left"
            : "dictionary-tab-content-left-hidden"
        }
      >
        <div
          className="dictionary-tab-left-bar-chevron-container"
          onClick={() => props.setShowLeftBar(props.showLeftBar ? false : true)}
        >
          {props.showLeftBar ? (
            props.showLeftBar && props.smallScreen ? (
              <Icon name="chevron down" />
            ) : (
              <Icon name="chevron left" />
            )
          ) : (
            <Icon name="chevron right" />
          )}
        </div>
        {props.showLeftBar ? (<div>No words with the same root were found</div>) : null}
      </div>
    );
  }
  return (
    <div
      className={
        props.showLeftBar
          ? "dictionary-tab-content-left"
          : "dictionary-tab-content-left-hidden"
      }
    >
      <div
        className="dictionary-tab-left-bar-chevron-container"
        onClick={() => props.setShowLeftBar(props.showLeftBar ? false : true)}
      >
        {props.showLeftBar || props.smallScreen ? (
          <div>Related Words</div>
        ) : null}
        {props.showLeftBar ? (
          props.showLeftBar && props.smallScreen ? (
            <Icon name="chevron down" />
          ) : (
            <Icon name="chevron left" />
          )
        ) : (
          <Icon name="chevron right" />
        )}
      </div>
      {props.showLeftBar ? (
        <>
          <div className="dictionary-tab-left-bar-headers">Same Root</div>
          {fireStoreData.wordsWithRoot.map((datum) => {
            if (datum.data.Root === props.tabData.Root) {
              return (
                <div
                  key={datum.id}
                  className="dictionary-tab-content-left-container"
                >
                  <div
                    onClick={() =>
                      props.dispatch({
                        browse: false,
                        type: "add",
                        tab: {
                          key: datum.id,
                          value: datum.id,
                          data: {
                            data: {
                              ...datum.data,
                              collectionName: datum.collectionName,
                            },
                          },
                          text: datum.data.English,
                        },
                      })
                    }
                    className={
                      props.tabData.English === datum.data.English
                        ? "dictionary-tab-content-left-text-selected"
                        : "dictionary-tab-content-left-text"
                    }
                  >
                    <div>
                      {datum.data.Language} - {datum.data.English}
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div className="dictionary-tab-left-bar-headers">Similar Root</div>
          {relatedWords.map((datum) => {
            if (datum.data.Root !== props.tabData.Root) {
              return (
                <div
                  key={datum.id}
                  className="dictionary-tab-content-left-container"
                >
                  <div
                    onClick={() =>
                      props.dispatch({
                        browse: false,
                        type: "add",
                        tab: {
                          key: datum.id,
                          value: datum.id,
                          data: {
                            data: {
                              ...datum.data,
                              collectionName: datum.collectionName,
                            },
                          },
                          text: datum.data.English,
                        },
                      })
                    }
                    className={
                      props.tabData.English === datum.data.English
                        ? "dictionary-tab-content-left-text-selected"
                        : "dictionary-tab-content-left-text"
                    }
                  >
                    <div>
                      {datum.data.Language} - {datum.data.English}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : null}
    </div>
  );
};

const DictionaryContentCenterEntry = (props) => {
  return (
    <div className="dictionary-tab-content-items">
      <div className="dictionary-tab-content-item-left">{props.left}</div>
      <div className="dictionary-tab-content-item-right">
        {props.right}
      </div>
    </div>
  );
};

const DictionaryContentCenter = (props) => {
  const fireStoreData = useSelector(selectFirestore);
  let sameRoot;
  if (fireStoreData.roots) {
    sameRoot = fireStoreData.roots.find((root) => {
      return root.data.Language === props.tabData.Root;
    });
  }
  let arr = [
    { left: "English", right: props.tabData.English },
    { left: "Animacy", right: props.tabData.Animacy },
    { left: "Category", right: props.tabData.Category },
    { left: "Grammatical Category", right: props.tabData.Grammatical_Category },
  ];
  // eslint-disable-next-line no-unused-vars
  let highlightWord = {
    color: 'cornflowerblue',
  }
  let greyWord = {
    color: 'rgba(100,100,100,1)',
  }
  // eslint-disable-next-line no-unused-vars
  let sameLine = {
    display: 'flex',
  }
  return (
    <div className="dictionary-tab-content-middle">
      <div className="dictionary-tab-middle-top">
        {arr.map((datum) => {
          return (
            <DictionaryContentCenterEntry
              key={datum.left}
              left={datum.left}
              right={datum.right}
            />
          );
        })}
        <div className="dictionary-tab-content-items">
          <div className="dictionary-tab-content-item-left">Root</div>
          <div className="dictionary-tab-content-items-roots">
            {props.tabData.Root} &nbsp;
            {sameRoot && sameRoot.data["Pronunciation Key"].length > 0
              ? ` (${sameRoot.data["Pronunciation Key"]})`
              : null}
          </div>
        </div>
        {sameRoot !== undefined && fireStoreData.relatedRoots !== undefined ? (
          <div className="dictionary-tab-content-items">
            <div className="dictionary-tab-content-item-left">
              Related Roots
            </div>
            <div>
              {fireStoreData.relatedRoots.map((root) => {
                if (
                  root.data.Language !== props.tabData.Root &&
                  sameRoot.data.English === root.data.English
                ) {
                  return (
                    <div
                      key={root.id}
                      className="dictionary-tab-content-items-roots"
                    >
                      {root.data["Language"]} &nbsp; (
                      {root.data["Pronunciation Key"]})
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : null}
      </div>

      <div className="dictionary-tab-middle-top-new">
        <div className="dictionary-tab-middle-top-new-header">
          {props.tabData.Root}
        </div>
        <div className="dictionary-tab-middle-top-new-content">
          <div>
            {props.tabData.Grammatical_Category}, {props.tabData.Animacy}
          </div>
          <div>Alternative forms:</div>
        </div>
        <div className="dictionary-tab-middle-top-new-sub-header">
          Plurality
        </div>
        {props.tabData["OBJ.Plurality"].length > 0 ? (
          <div className="dictionary-tab-middle-top-new-content">
            Object Plurality: {props.tabData["OBJ.Plurality"]}
          </div>
        ) : null}
        {props.tabData["SUB.Plurality"].length > 0 ? (
          <div className="dictionary-tab-middle-top-new-content">
            Subject Plurality: {props.tabData["SUB.Plurality"]}
          </div>
        ) : null}
        {/* <div className="dictionary-tab-middle-top-new-sub-header">
          Possession
        </div>
        <div className="dictionary-tab-middle-top-new-content">
          Next Section
        </div>
        <div className="dictionary-tab-middle-top-new-content">
          Next Section
        </div>
        <div className="dictionary-tab-middle-top-new-content">
          Next Section
        </div>
        <div className="dictionary-tab-middle-top-new-sub-header">
          Adjective Forms
        </div>
        <div className="dictionary-tab-middle-top-new-content">
          Next Section
        </div> */}
      </div>
    </div>
  );
};

// ==> separate functions not components <== //

function formatDataForDropDown(dataFromDatabase, langType) {
  console.log(" → dataFromDatabase", dataFromDatabase);
  // console.log("data from redux: ",dataFromDatabase);
  let langString = langType === 'English' ? 'English' : 'Language';
  return dataFromDatabase.map((d) => {
    if (d.data.English.length < 1) {
      return {
        key: d.id,
        text: d.data[langString],
        value: d.id,
        data: d,
        content: <div>{d.data[langString]} -- <span style={{ color: "red" }}>bad data</span> </div>
      }
    }
    return {
      key: d.id,
      text: d.data[langString],
      value: d.id,
      data: d,
      content:
        langString === "English" ? (
          <div>
            {d.data[langString]} - {d.data["Grammatical_Category"]}
          </div>
        ) : (
          <div>
            {d.data[langString]} ({d.data.English}) - {d.data["Grammatical_Category"]}
          </div>
        ),
    };
  });
}

export default Dictionary;

Dictionary.propTypes = {};
