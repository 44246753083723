/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  addDoc,
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  arrayUnion,
  deleteDoc,
} from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import { rest, uniqBy } from "lodash";
import config from "../configData";
import { compare } from '../Components/nonUIFuncs';

// ==> Firebase Config & database setup <== //

// ==> Client Config <== //
import { firebaseConfig } from "../Config/Colville-nse/clientConfig";
// ===================== // 

window.app = initializeApp(firebaseConfig);
const db = getFirestore(window.app);
const storage = getStorage();
const functions = getFunctions(window.app);
const listAllUsersCall = httpsCallable(functions, 'listAllUsersCall');
const updateUserCall = httpsCallable(functions, 'updateUserCall');

//const functions = getFunctions(window.app);

const CONFIG = config(); // load config data as object
// ================================== //

const currentDocRef = {};
let previousDocs = [];

export const firestoreSlice = createSlice({
  name: "firestore",
  initialState: {
    value: {},
  },
  reducers: {
    clearData: (state) => (state.value = []),
    getCollectionNamesR: (state, action) => {
      state.value = { ...state.value, collectionNames: action.payload };
    },
    getOptionsR: (state, action) => {
      let oldStateOptions = { ...state.value.options };
      oldStateOptions[action.payload.id] = action.payload.data;
      state.value = { ...state.value, options: oldStateOptions };
    },
    fileUploadStatus: (state, action) => {
      state.value = { ...state.value, fileUploadStatusResponse: action.payload };
    },
    getSearchR: (state, action) => {
      state.value = { ...state.value, getSearchResults: action.payload };
    },
    getFullData: (state, action) => {
      state.value = { ...state.value, firebaseCollectionData: action.payload };
    },
    updateUserData: (state, action) => {
      state.value = { ...state.value, updateUserDataResponse: action.payload };
    },
    getPagedData: (state, action) => {
      state.value = { ...state.value, firebaseCollectionData: action.payload };
    },
    getSearchData: (state, action) => {
      state.value = { ...state.value, searchDataResults: action.payload };
    },
    getURLData: (state, action) => {
      state.value = { ...state.value, urlDataResults: action.payload };
    },
    getSearchData2: (state, action) => {
      state.searchData2 = { ...state.searchData2, test: action.payload };
    },
    getQuizData: (state, action) => {
      state.value = { ...state.value, quizData: action.payload };
    },
    // TODO: Make sure all roots previously existing still exist after update
    getRootsData: (state, action) => {
      state.value = { ...state.value, roots: action.payload.docs };
    },
    getBrowseRestults: (state, action) => {
      state.value = { ...state.value, browseResults: action.payload };
    },
    setProductList: (state, action) => {
      state.value = { ...state.value, productList: action.payload };
    },
    setNewCollectionID: (state, action) => {
      state.value = { ...state.value, newFirebaseID: action.payload };
    },
    setUserPrefs: (state, action) => {
      state.value = { ...state.value, userPrefs: action.payload };
    },
    getRelatedRootsData: (state, action) => {
      let oldRoots = state.value.relatedRoots;
      let allRoots =
        oldRoots === undefined
          ? action.payload.relatedDocs
          : [...oldRoots, ...action.payload.relatedDocs];
      let keys = [];
      let filteredRoots = allRoots.map((datum) => {
        if (!keys.includes(datum.id)) {
          keys.push(datum.id);
          return datum;
        }
      });
      filteredRoots = filteredRoots.filter((datum) => datum !== undefined);
      state.value = { ...state.value, relatedRoots: filteredRoots };
    },
    getWordsWithRootData: (state, action) => {
      let oldWords = state.value.wordsWithRoot;
      let allWords =
        oldWords === undefined
          ? action.payload.docs
          : [...oldWords, ...action.payload.docs];
      let keys = [];
      let filteredWords = allWords.map((datum) => {
        if (!keys.includes(datum.id)) {
          keys.push(datum.id);
          return datum;
        }
      });
      filteredWords = filteredWords.filter((datum) => datum !== undefined);
      state.value = { ...state.value, wordsWithRoot: filteredWords };
    },
    updateUserPrefsStyle: (state, action) => {
      let collection = Object.keys(action.payload)[0];
      state.value = {
        ...state.value,
        userPrefs: {
          ...state.value.userPrefs,
          style: {
            ...state.value.userPrefs.style,
            [collection]: {
              ...state.value.userPrefs.style[collection],
              ...action.payload,
            },
          },
        },
      };
    },
    setEditableGameData: (state, action) => { //BUG: minor error in this?
      state.value = {
        ...state.value,
        editableGameData: {
          ...state.value.editableGameData,
          ...action.payload,
        },
      };
    },
  },
});

// action names

export const {
  getFullData,
  getCollectionNamesR,
  getSearchR,
  getPagedData,
  getSearchData,
  getSearchData2,
  getOptionsR,
  fileUploadStatus,
  getQuizData,
  getRootsData,
  getRelatedRootsData,
  getWordsWithRootData,
  getBrowseRestults,
  getURLData,
  setProductList,
  setNewCollectionID,
  setUserPrefs,
  setEditableGameData,
  updateUserPrefsStyle,
  updateUserData,
} = firestoreSlice.actions;

// Functions to get data from server

// export const getMediaFull = () => (dispatch) => {
//   const url = "";
// };

export const getFullDataAsync = () => (dispatch) => {
  // this uses google clouds REST API
  // const url2 = `https://us-central1-dict2test.cloudfunctions.net/readDataBase?asarray=true`;
  const url = `https://firestore.googleapis.com/v1beta1/projects/dict2test/databases/(default)/documents/Make`;

  axios.get(url).then((res) => {
    console.log("response: ", res.data);
    dispatch(getFullData(res.data));
  });
};

export const changeUserData = ({ userID, userData, newUser = false, delUser = false }) => async (dispatch) => {
  if (delUser) { // del user 
    let delUserResult = await updateUserCall({ delUser: true, userID: userID });
    // console.log("delUserResult: ", delUserResult);
    dispatch(updateUserData({ userID: delUserResult.userID, what: "deleted user", sucess: true }));
  } else if (newUser) { // create a new user instead of updating one
    let newuser = {
      email: userData.email,
      password: userData.password || "newuser123pass",
      displayName: userData.displayName,
      disabled: userData.disabled,
    };
    let newUserResult = await updateUserCall({ newUser: true, user: newuser });
    // console.log("results form new user: ", newUserResult);
    dispatch(updateUserData({ userID: newUserResult, what: "new user", sucess: true }));
  } else { // update user information
    console.log("userData: ", userData);
    let testResult = await updateUserCall({ user: { uid: userID } });
    dispatch(updateUserData({ userID: testResult, what: "Update User information", sucess: true }));
  }
}

export const getFullDataFirebaseCollection = (collectionName) => async (dispatch) => {
  const docsSnap = await getDocs(collection(db, collectionName));
  const docs = docsSnap.docs.map((document) => {
    return { id: document.id, data: document.data() };
  });
  dispatch(getFullData({ [collectionName]: docs }));
};

// Media Library
const getPagedDataCollectionLanguage = ({
  collectionName,
  showImages = false,
  showAudio = false,
  newSearch = false,
  filterTxt,
  searchNative = false,
  typeToFind = [],
  numResults = 10,
}) => {
  // purpose: get results from the array of language
  let queries = {};
  let docRef = collection(db, collectionName);

  queries.exact = query(
    docRef,
    where("type", "in", typeToFind),
    where("Language.BF", ">=", filterTxt),
    where("Language.BF", "<", filterTxt + "z"),
    orderBy("Language.BF", "asc"),
    limit(5)
  );

  let filterTxtA = filterTxt.split("");
  let possibleChars = filterTxtA.reduce((acc, chr, idx) => {
    acc[idx] = CONFIG.lookupTable[chr];
    return acc;
  }, []);

  const getCombn = (arr, pre = "") => {
    if (!arr.length) {
      return pre;
    }
    let ans = arr[0].reduce((ans, value) => {
      return ans.concat(getCombn(arr.slice(1), pre + value));
    }, []);
    return ans;
  };
  let words = getCombn(possibleChars);
  words.map((word, idx) => {
    queries[idx] = query(
      docRef,
      where("type", "in", typeToFind),
      where("Language.BF", ">=", word),
      where("Language.BF", "<", word + "z"),
      orderBy("Language.BF", "asc"),
      limit(5)
    );
  });
  // console.log("querries: ", queries);
  return queries;
};

// ==> Dictionary Quiz <== //
export const getPagedQuizData = (searchedData) => async (dispatch) => {
  let docRef;
  if (searchedData.collectionName && searchedData.collectionName.length > 1) {
    docRef = collection(db, searchedData.collectionName);
  } else {
    docRef = collection(db, "Words");
  }

  if (
    searchedData.Part_Of_Speach === undefined &&
    searchedData.Grammatical_Category === undefined
  ) {
    return;
  }
  const myQuery = query(
    docRef,
    where("Grammatical_Category", "==", searchedData.Grammatical_Category),
    where(
      "Category",
      "==",
      searchedData.Category === undefined ? "" : searchedData.Category
    ),
    limit(15)
  );
  let results = await getDocs(myQuery);
  let docs = [];
  results.forEach((doc) => {
    docs.push({ id: doc.id, data: doc.data() });
    currentDocRef[searchedData.collectionName] = doc;
  });
  docs = uniqBy(docs, "id");
  let docData = [];
  docs.map((datum) => {
    docData.push(datum.data);
  });
  try {
    docData.push(JSON.parse(localStorage.QuizData));
  } catch (e) {
    console.log('error', e);
  }
  dispatch(getQuizData(docData));
};

// Media Library
export const getPagedDataCollection =
  ({
    collectionName,
    showImages = false,
    showAudio = false,
    newSearch = false,
    filterTxt,
    searchNative = false,
  }) =>
    async (dispatch) => {
      let docs = [];
      // let languageTxt = "English";

      let docRef = collection(db, collectionName);
      let myquery;
      let typeToFind = [];
      if (showImages) {
        typeToFind.push("image");
      }
      if (showAudio) {
        typeToFind.push("audio");
      }
      if (newSearch) {
        currentDocRef[collectionName] = "";
        previousDocs = [];
      }
      if (typeToFind.length < 1) {
        console.log("I am in the passing if");
        myquery = new Promise((resolve) => {
          resolve([]);
        });
      } else if (searchNative) {
        // do myquery with native langauge
        if (filterTxt.length < 1) {
          myquery = new Promise((resolve) => {
            resolve([]);
          });
        } else {
          let myqueryRefs = getPagedDataCollectionLanguage({
            collectionName,
            showImages,
            showAudio,
            newSearch,
            filterTxt,
            typeToFind,
          });
          let docsAfunc = async (myqueryRef) => {
            return myqueryRef.get();
          };
          // convert into array for Promise.all
          let myqueryRefsA = [];
          myqueryRefsA.push(myqueryRefs.exact); // this should but the exact at top of list
          for (const key in myqueryRefs) {
            if (key !== "exact") {
              myqueryRefsA.push(myqueryRefs[key]);
            }
          }
          // get the documents from the querries
          let docPromises = myqueryRefsA.map(docsAfunc);
          let t = [];
          myquery = Promise.all(docPromises).then((snapshotA) => {
            snapshotA.map((snapshot) => {
              snapshot.forEach((doc) => {
                // console.log("documents from snaptop: ", doc.data());
                t.push(doc);
              });
            });
            return t;
          });
        }
      } else {
        if (filterTxt && filterTxt.length >= 1) {
          if (currentDocRef[collectionName]) {
            // pulling more data affter inital
            myquery = query(
              where("type", "in", typeToFind),
              where("English", ">=", filterTxt),
              where("English", "<", filterTxt + "z"),
              orderBy("English", "asc"),
              limit(10)
            );
          } else {
            // inital get of data
            myquery = query(
              docRef,
              where("type", "in", typeToFind),
              where("English", ">=", filterTxt),
              where("English", "<", filterTxt + "z"),
              orderBy("English", "asc"),
              limit(30)
            );
          }
        } else {
          // do not filter by english
          if (currentDocRef[collectionName]) {
            // pulling more data affter inital
            myquery = query(
              docRef,
              where("type", "in", typeToFind),
              // where("English", ">=", 0),
              // where("English", "<=", "z"),
              orderBy("English", "asc"),
              limit(10),
              startAfter(currentDocRef[collectionName])
            );
          } else {
            // inital get of data
            // console.log(db);
            // myquery = db.ref(collectionName);
            // myquery.orderBy('English').startAt(0).endAt('\u005A').limit(30)
            myquery = query(
              docRef,
              where("type", "in", typeToFind),
              // where("English", ">=", 0),
              // where("English", "<=", "z"),
              orderBy("English", "asc"),
              limit(30)
            );
            // console.log(myquery);
          }
        }
      }

      const myqueryDocs = getDocs(myquery);

      if (
        typeof myqueryDocs == "undefined" ||
        myqueryDocs?.then === "undefined" ||
        typeof myqueryDocs.then !== "function"
      ) {
        // catches an error that should not exist.
        return;
      }

      myqueryDocs.then((myquerySnapshot) => {
        console.log("response: ", myquerySnapshot);
        myquerySnapshot.forEach((doc) => {
          docs.push({ id: doc.id, data: doc.data() });
          currentDocRef[collectionName] = doc;
        });
        docs = uniqBy(docs, "id"); // remove dups

        // TODO: duplicate documents sometimes when scrolling.

        if (!newSearch) {
          let combinedDocs = [...previousDocs, ...docs];
          dispatch(getPagedData({ [collectionName]: combinedDocs }));
        } else {
          dispatch(getPagedData({ [collectionName]: docs }));
          previousDocs = [];
        }
        previousDocs = [...previousDocs, ...docs];
      });
    };

// TODO: remove this uses the REST API and is slower & less capable.
export const getSearchStartsWith = (searchQuery) => (dispatch) => {
  if (searchQuery.length < 3) {
    // abort query if chars are to few.
    return;
  }
  const url = `https://us-central1-blackfoot-426cd.cloudfunctions.net/searchStartsWith?english=${searchQuery}`;
  axios
    .get(url)
    .then((res) => {
      // console.log("response: ", res.data);
      dispatch(getSearchR(res.data));
    })
    .catch((err) => console.warn("bad network: ", err));
};

// Tags
export const setTags =
  ({ tag }) =>
    async (dispatch) => {
      // testing
      await setDoc(doc(db, "adminFieldOptionsTEST", "something"), {
        name: "some place",
        somethingelse: "somerandom text",
      });
      const tagsRef = doc(db, "adminFieldOptions", "tags");
      await updateDoc(tagsRef, {
        options: arrayUnion(tag),
      });

      //TODO: update data after adding tag
      const tagSnap = await getDoc(tagsRef);
      if (tagSnap.exists()) {
        dispatch(getOptionsR({ id: "tags", data: tagSnap.data() }));
      }
    };

// get Roots
export const getRoots = ({ rootWordEnglish, root }) => async (dispatch) => {
  if (root !== undefined) {
    let docs = [];
    let relatedDocs = [];
    let docRef = collection(db, "Roots");
    let docQuery = [];
    if (root !== undefined) {
      const docQueryP = query(docRef, where("Language", "==", root));
      docQuery = getDocs(docQueryP);
    }

    let relatedWordsEnglish = rootWordEnglish;
    let relatedDocQuery = {};

    docQuery.then((result) => {
      result.forEach((doc) => {
        let docData = doc.data();
        relatedWordsEnglish = docData.English;
        docs.push({ data: docData, id: doc.id });
        return docData.English;
      });
      dispatch(getRootsData({ rootWordEnglish, docs }));
    })
      .then(() => {
        const relatedDocQueryR = query(
          docRef,
          where("English", "==", relatedWordsEnglish)
        );
        relatedDocQuery = getDocs(relatedDocQueryR);
        relatedDocQuery.then((result) => {
          result.forEach((doc) => {
            let docData = doc.data();
            relatedDocs.push({ data: docData, id: doc.id, relatedWord: rootWordEnglish });
          });
          dispatch(getRelatedRootsData({ rootWordEnglish, relatedDocs }));
        });
      });
  }
};


const fetchAllRoots = async (rootEnglish, root, setRoots) => {
  let allRoots = [];
  if (root === undefined) { return null }
  let docRef = collection(db, "Roots");
  const relatedDocQueryR = query(docRef, where("English", "==", rootEnglish));
  let relatedDocQuery = getDocs(relatedDocQueryR);
  await relatedDocQuery.then((result) => {
    result.forEach((doc) => {
      let docData = doc.data();
      allRoots.push(docData.Language);
    });
  }).then(() => setRoots(allRoots));
  return allRoots;
};

export const getOtherWordsWithRoot =
  ({ rootEnglish, root }) =>
    async (dispatch) => {
      let allRelatedRoots = [];
      const setRoots = arr => {
        allRelatedRoots = arr;
      };
      await fetchAllRoots(rootEnglish, root, setRoots);


      let docs = [];
      let queries = [];
      // When working with phrases we will be dealing with possible multiple Roots.
      const collectionsToSearch = ["Words"];

      let docRefs = collectionsToSearch.map((collectionName) =>
        collection(db, collectionName)
      );
      // let docRef = collection(db, 'Words');
      allRelatedRoots.map((rt) => {
        if (rt.length > 0) {
          queries = docRefs.map((ref) => {
            const myquery = query(ref, where("Root", "==", rt));
            return getDocs(myquery);
          });
          Promise.all(queries).then((q) => {
            q.forEach((query) => {
              query.forEach((doc) => {
                let collectionName = doc.ref.path.match(/^(.*?)\//)[1]; //undocumented collection name path
                let docData = doc.data();
                docs.push({
                  data: docData,
                  id: doc.id,
                  collectionName: collectionName,
                });
              });
            });
          }).then(() => {
            dispatch(getWordsWithRootData({ root, docs }));
          });
        }
      });
    };

// ==> Search with possible filters <== //
//used with main dict search.
export const getSearchStartsWithFilter =
  ({
    searchString,
    searchNative = false,
    newSearch = true,
    numResults = 5,
    collectionNames = [], // Deprechated
    grammaticalCategory = [],
    Advanced = false,
    pluralization,
    animacy,
    partOfSpeech,
    personView,
    tense,
    transitivity,
    progressiveness,
  }) => async (dispatch) => {
    console.log("GrammaticalCategorys being searched: ", grammaticalCategory);
    // console.log("collectionNames:  ", collectionNames);
    let docs = [];
    // eslint-disable-next-line no-unused-vars
    let queries = [];
    // TODO: No 'Words' collection in Salish, causes issues
    let collectionRef = collection(db, 'Words');
    let language = (searchNative) ? "Language" : "English_lower";
    let searchStringLower = searchString.toLowerCase();

    if (grammaticalCategory.length < 1) {
      // don't filter
      let querry = query(
        collectionRef,
        where(language, ">=", searchStringLower),
        where(language, "<", searchStringLower + "z"),
        orderBy(language, "asc"),
        limit(numResults)
      );
      let docsRef = await getDocs(querry);
      if (typeof docsRef.docs == "undefined") return;
      docsRef.forEach(d => {
        // pushing more data into the data and data is now subset within
        docs.push({
          id: d.id,
          data: d.data(),
          collectionName: "Words"
        });
      });
    } else {
      // with filters
      let queries = grammaticalCategory.map(cat => {
        //TODO: some work here
        let querry = query(
          collectionRef,
          where(language, ">=", searchStringLower),
          where(language, "<", searchStringLower + "z"),
          where("Grammatical_Category", "==", cat),
          orderBy(language, "asc"),
          limit(numResults)
        );
        return getDocs(querry);
      });
      let queries_after = await Promise.all(queries);
      queries_after.forEach(ref => {
        console.log("test: ", ref.docs);
        let returnedDocsRefs = ref.docs;
        returnedDocsRefs.forEach(d => {
          docs.push({
            id: d.id,
            data: d.data(),
            collectionName: "Words"
          });
        })
      });
      console.log("docs: ", docs);
    }
    if (docs.length < 1) return;
    let uniqDocs = uniqBy(docs, "id");
    // console.log("before dispatch: ", uniqDocs);
    dispatch(getSearchData(uniqDocs));
  }

// Search Query in firebase native function type
export const getSearchStartsWithMultiCollection =
  ({
    searchString,
    searchNative = false,
    newSearch = true,
    numResults = 5,
    collectionNames = ['Words'],
    grammaticalCategory = [],
  }) =>
    (dispatch) => {
      let docs = [];
      let queries = [];

      let docRefs = collectionNames.map((collectionName) =>
        collection(db, collectionName)
      );
      if (collectionNames.length < 1 || searchString.length < 1) {
        // early exit
        queries.push(new Promise((resolve) => resolve([])));
      } else {
        // Normal flow
        queries = docRefs.map(async (ref) => {
          let querry = query(
            ref,
            where("English", ">=", searchString),
            where("English", "<", searchString + "z"),
            orderBy("English", "asc"),
            limit(numResults)
          );
          return getDocs(querry);
        });
        // console.log("queries: ", queries);
      }
      // TODO: consider Promise.final for this
      Promise.all(queries).then(async (qs) => {
        // console.log("searchString: ", searchString);
        // console.log("=>> ", qs);
        // console.log("==> ", qs.length);
        qs.forEach((doc) => {
          console.log("==> ", doc.docs);
          if (typeof doc.docs == "undefined") return;
          doc.docs.forEach((d) => {
            let collectionName = d.ref.path.match(/^(.*?)\//)[1]; //undocumented collection name path
            let data = d.data();
            data.collectionName = collectionName;
            data.firebaseID = d.id;
            docs.push({
              id: d.id,
              data: data,
              collectionName: collectionName,
            });
          });
        });
        let uniqDocs = uniqBy(docs, "id");
        dispatch(getSearchData(uniqDocs));
      });
    };

export const getURLWord = (collectionName, id) => async (dispatch) => {
  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);
  let snapData = docSnap.data();
  if (snapData !== undefined) {
    snapData.collectionName = collectionName;
    snapData.firebaseID = docSnap.id;
    let data = {
      id: docSnap.id,
      data: snapData,
      collectionName: collectionName,
    };
    dispatch(getURLData(data));
  }
};

export const getProductList = () => async (dispatch) => {
  let collectionName = "Product List & Status";
  const colRef = collection(db, collectionName);
  const colQuery = query(colRef);
  const colSnap = await getDocs(colQuery);
  let snapData = [];
  colSnap.docs.forEach((d) => {
    let data = d.data();
    data.collectionName = collectionName;
    data.firebaseID = d.id;
    snapData.push({
      id: d.id,
      data: data,
      collectionName: collectionName,
    });
  });
  dispatch(setProductList(snapData));
};

// Options
export const getOptions =
  ({ field, myCollection }) =>
    async (dispatch) => {
      let docRef = collection(db, "adminFieldOptions");
      let myquery = query(
        docRef,
        where("field", "==", field),
        where("collection", "==", myCollection)
      );
      let myDoc = await getDocs(myquery);
      if (!myDoc?.docs[0]) {
        console.log("doc: ", myDoc.docs[0]);
        return;
      }
      let data = myDoc.docs[0].data();
      let id = myDoc.docs[0].id;

      dispatch(getOptionsR({ id: id, data: data }));
    };

export const findBrowseResults = ({
  search,
  lang,
  animacy,
  partOfSpeach,
  personView,
  pluralization,
  progressiveness,
  tense,
  transitivity,
}) => (dispatch) => {
  let collectionNames = ["Words"];
  // let collectionNames = ["I_Verbs", "I_Nouns", "Words"];
  let docs = [];
  let queries = [];
  const docRefs = collectionNames.map((collectionName) =>
    collection(db, collectionName)
  );


  docRefs.forEach((ref) => {
    let upperCase = search.toUpperCase();
    let lowerCase = search.toLowerCase();
    const promiseRef = query(
      ref,
      where(lang, ">=", upperCase),
      where(lang, "<", upperCase + "z"),
      orderBy(lang, "asc")
    );
    const promiseRef2 = query(
      ref,
      where(lang, ">=", lowerCase),
      where(lang, "<", lowerCase + "z"),
      orderBy(lang, "asc")
    );
    const promise = getDocs(promiseRef);
    const promiseTwo = getDocs(promiseRef2);
    queries.push(promise, promiseTwo);
  });

  Promise.all(queries).then((q) => {
    q.forEach((query) => {
      query.forEach((doc) => {
        let collectionName = doc.ref.path.match(/^(.*?)\//)[1];
        let data = doc.data();
        data.collectionName = collectionName;
        data.firebaseID = doc.id;
        docs.push({
          id: doc.id,
          data: data,
          collectionName: collectionName,
        });
      });
    });
    docs = docs.sort((a, b) => compare(a.data.English, b.data.English));
    dispatch(getBrowseRestults(docs));
  });
};

export const getCollectionNames = () => (dispatch) => {
  const url = `https://us-central1-blackfoot-426cd.cloudfunctions.net/getCollections`;
  axios
    .get(url)
    .then((res) => {
      // console.log("response: ", res.data.collections);
      dispatch(getCollectionNamesR(res.data.collections));
    })
    .catch((err) => console.warn("bad network: ", err));
};

export const updateUserStyles =
  (collectionName, newDocumentObj, user) => async (dispatch) => {
    try {
      const userPrefRef = await doc(db, "userPrefs", user);
      setDoc(
        userPrefRef,
        {
          style: {
            [collectionName]: {
              [newDocumentObj.firebaseID]: newDocumentObj.style,
            },
          },
        },
        { merge: true }
      );
      dispatch(
        updateUserPrefsStyle({
          [newDocumentObj.firebaseID]: newDocumentObj.style,
        })
      );
      // console.log("Success", newDocumentObj.firebaseID);
    } catch (e) {
      console.log("UserPref Fail", e);
    }
  };

export const updateUserColumnPrefs = async (
  collectionName,
  columnObj,
  user
) => {
  let fixColumnObj = { ...columnObj };
  if (fixColumnObj.length === undefined) {
    delete fixColumnObj.filterEditor;
    delete fixColumnObj.header;
    delete fixColumnObj.onRender;
    delete fixColumnObj.render;
    try {
      fixColumnObj.headerProps.selectColumn =
        fixColumnObj.headerProps.selectColumn || false;
    } catch (e) {
      console.log("Failed to alter headerProps.selectColumn on save column");
    }
  }
  try {
    const userPrefRef = await doc(db, "userPrefs", user);
    setDoc(
      userPrefRef,
      {
        columnPrefs: {
          [collectionName]:
            columnObj.length !== undefined
              ? {
                columnOrder: columnObj,
              }
              : {
                [columnObj.id]: { ...fixColumnObj },
              },
        },
      },
      { merge: true }
    );
    console.log("Success", collectionName, fixColumnObj);
  } catch (e) {
    console.log("UserPref Fail", e);
  }
};

export const updateUserColors = async (colorObj, user) => {
  try {
    const userPrefRef = await doc(db, "userPrefs", user);
    setDoc(userPrefRef, { recentColors: colorObj }, { merge: true });
    console.log("Success", colorObj);
  } catch (e) {
    console.log("UserPrefColors Fail", e);
  }
};

export const createDocument =
  (collectionName, obj = {}) =>
    async (dispatch) => {
      const docRef = await addDoc(collection(db, collectionName), {
        ...obj,
        collectionName: collectionName,
      });
      // console.log("New Id", docRef.id);
      dispatch(
        setNewCollectionID({
          ...obj,
          collectionName: collectionName,
          firebaseID: docRef.id,
        })
      );
    };

export const deleteDocument = async (collectionName, firebaseId) => {
  console.log("deleting:", collectionName, firebaseId);
  // await deleteDoc(doc(db, collectionName, firebaseId));
};

export const getUserPrefs = (user) => async (dispatch) => {
  const docRef = await getDoc(doc(db, "userPrefs", user));
  let snapData = docRef.data();
  dispatch(setUserPrefs(snapData));
};

export const updateDocument = async (
  collectionName,
  newDocumentObj,
  column
) => {
  let testCollection = collectionName;
  // if (!testCollection.includes("_test")) {
  //   testCollection = testCollection + "_test";
  // }
  // console.log("newDocumentObj", newDocumentObj);
  try {
    const docRef = await doc(db, testCollection, newDocumentObj.firebaseID);
    // TODO: remove Edit and style from the saved data.
    if (column !== undefined) {
      console.log("if");
      setDoc(docRef, { [column]: newDocumentObj[column] }, { merge: true });
      // console.log("Success", docRef.id);
    } else {
      console.log("else");
      let keys = Object.keys(newDocumentObj);
      keys = keys.filter((k) => k !== "Edit" && k !== "style");
      keys.map((k) => {
        setDoc(docRef, { [k]: newDocumentObj[k] }, { merge: true });
      });
    }
  } catch (e) {
    console.log("Fail", e);
  }
};

export const getGameData = (collectionName) => async (dispatch) => {
  const docsSnap = await getDocs(collection(db, collectionName));
  const docs = docsSnap.docs.map((document) => {
    return { ...document.data(), firebaseID: document.id };
  });
  dispatch(setEditableGameData({ [collectionName]: docs }));
};

export const fileUpload = (file, filename) => async (dispatch) => {
  // TODO: upload status: https://firebase.google.com/docs/storage/web/upload-files
  console.log("trying to uplaod a file");
  const storageRef = ref(storage, `${filename}`);
  const snapshot = await uploadBytes(storageRef, file);
  console.log("Uploaded a file", snapshot);
  dispatch(fileUploadStatus({ name: snapshot.metadata.name }));
};

export const uploadFromSting = ({ string, filename, dir = "Image" }) => async (dispatch) => {
  const storageRef = ref(storage, `${dir}/${filename}`);
  console.log("before the upload");
  let snapshot = await uploadString(storageRef, string, "data_url");
  console.log("Uploaded a string", snapshot);
  dispatch(fileUploadStatus({ name: snapshot.metadata.name }));
};

export default firestoreSlice.reducer;

export const selectFirestore = (state) => state.firestore.value;
